import { BatchContext } from "./BatchContext";
import { OperationContext } from "./OperationContext";
import { GetSelectedAndFocused } from "./operations/GetSelectedAndFocused";

import { WordWrapper } from "./WordWrapper";

const searchOptions = {
  matchCase: false,
  matchWholeWord: true,
  ignorePunct: false,
  ignoreSpace: true,
  matchWildcards: false,
};

export class SearchAndSelect {
  static shouldForceQuit = false;
  static running = false;

  static forceQuit() {
    this.shouldForceQuit = true;
  }

  /** Search for a string in the document then select each instance and run a function.
   *
   * @param search The string to search for
   * @param thunk The function to run after each selection
   * @param setMatchCount Optional function to set the total number of matches found
   */
  static async run(
    search: string,
    thunk: (param: { total: number; current: number }) => Promise<void>,
    onProgress?: (params: { total: number; current: number }) => void,
  ): Promise<void> {
    await WordWrapper.run(async (context) => {
      if (this.running) {
        throw new Error("SearchAndSelect is already running");
      }

      this.running = true;
      try {
        const searchResults = context.document.body.search(search, searchOptions);
        context.load(searchResults, "items");
        await context.sync();

        onProgress?.({ total: searchResults.items.length, current: 0 });

        for (let i = 0; i < searchResults.items.length; i++) {
          if (this.shouldForceQuit) {
            this.shouldForceQuit = false;
            break;
          }
          const result = searchResults.items[i];
          result.select();
          await context.sync();

          // Skip if the selection is already a part of a content control
          const op = new GetSelectedAndFocused();
          const { selectedIds } = await op.commit(new OperationContext(op, new BatchContext(context, 1)));
          if (selectedIds.length > 0) {
            onProgress?.({ total: searchResults.items.length, current: i });
            continue;
          }

          await thunk({ total: searchResults.items.length, current: i });
          onProgress?.({ total: searchResults.items.length, current: i });
        }
      } finally {
        this.running = false;
      }
    });
  }
}
