import { useId } from "@fluentui/react-components";
import { WalterUser, WalterWorkspace } from "@src/lib/auth/OAuthService";
import debug from "@src/lib/debug";
import { DynamicElement } from "@src/lib/liquidx/DynamicElement";
import { defaultStorage } from "@src/lib/settings/defaultStorage";
import { SelectionDetails } from "@src/lib/sync-engine/operations/GetSelection";
import { type SetStateAction, type JSX, useReducer } from "react";

export interface AppState {
  autoOpen: boolean;
  bottomDialog: JSX.Element | null;
  currentUser: WalterUser | null;
  currentWorkspace: WalterWorkspace | null;
  documentSelectionData: SelectionDetails;
  dynamicElements: DynamicElement[];
  isFieldSetSelected: boolean;
  isLoggedIn: boolean;
  platform?: Office.PlatformType;
  selectedDynamicElementIds: number[];
  setBottomDialog: (value: SetStateAction<JSX.Element | null>) => void;
  setDocumentSelectionData: (value: SetStateAction<SelectionDetails>) => void;
  setIsFieldSetSelected: (value: SetStateAction<boolean>) => void;
  setIsLoggedIn: (state: boolean) => void;
  setSelectedDynamicElementIds: (value: SetStateAction<number[]>) => void;
  switchWorkspace: (workspace: WalterWorkspace | null) => void;
  showMigrateButton: boolean;
  showNewUi: boolean;
  toasterId: string;
  setAutoOpen: (value: boolean) => void;
  replacementsCount: number;
  replacementsCompleteCount: number;
  setReplacementsCount: (count: number) => void;
  setReplacementsCompleteCount: (count: number) => void;
}

type Actions =
  | "SET_DOCUMENT_SELECTION_DATA"
  | "SET_SELECTED_DYNAMIC_ELEMENT_IDS"
  | "SET_IS_FIELD_SET_SELECTED"
  | "SET_BOTTOM_DIALOG"
  | "SET_IS_LOGGED_IN"
  | "SET_SHOW_NEW_UI"
  | "SET_PLATFORM"
  | "SET_CURRENT_USER"
  | "SET_AUTO_OPEN"
  | "UPDATE_CURRENT_WORKSPACE"
  | "SET_REPLACEMENTS_COUNT"
  | "SET_REPLACEMENTS_COMPLETE_COUNT";

export function stateReducer(
  state: AppState,
  action: {
    type: Actions;
    payload: any;
  },
) {
  switch (action.type) {
    case "SET_DOCUMENT_SELECTION_DATA":
      return { ...state, documentSelectionData: action.payload };
    case "SET_SELECTED_DYNAMIC_ELEMENT_IDS":
      return { ...state, selectedDynamicElementIds: action.payload };
    case "SET_IS_FIELD_SET_SELECTED":
      return { ...state, isFieldSetSelected: action.payload };
    case "SET_BOTTOM_DIALOG":
      return { ...state, bottomDialog: action.payload };
    case "SET_IS_LOGGED_IN":
      return { ...state, isLoggedIn: action.payload };
    case "SET_PLATFORM":
      return { ...state, platform: action.payload };
    case "SET_SHOW_NEW_UI":
      return { ...state, showNewUi: action.payload.showNewUi, showMigrateButton: action.payload.updatedShowNewUi };
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload };
    case "SET_AUTO_OPEN":
      return { ...state, autoOpen: action.payload };
    case "UPDATE_CURRENT_WORKSPACE":
      return {
        ...state,
        currentWorkspace: action.payload,
      };
    case "SET_REPLACEMENTS_COUNT":
      return {
        ...state,
        replacementsCount: action.payload,
      };
    case "SET_REPLACEMENTS_COMPLETE_COUNT":
      if (action.payload === state.replacementsCount) {
        return {
          ...state,
          replacementsCount: 0,
          replacementsCompleteCount: 0,
        };
      } else {
        return {
          ...state,
          replacementsCompleteCount: action.payload,
        };
      }

    default:
      return state;
  }
}

export const defaultSelectionDetails: SelectionDetails = {
  isEmpty: true,
  paragraphsCount: 0,
  paragraphTextsAreEmpty: true,
  isInTableCell: false,
  isInTable: false,
};

export const defaultAppState: AppState = {
  autoOpen: false,
  currentUser: null,
  currentWorkspace: null,
  documentSelectionData: defaultSelectionDetails,
  dynamicElements: [],
  platform: undefined,
  selectedDynamicElementIds: [],
  isFieldSetSelected: false,
  setDocumentSelectionData: () => {},
  setSelectedDynamicElementIds: () => {
    debug.error("if this has been called, WalterProvider isn't being used properly");
  },
  setIsFieldSetSelected: () => {},
  toasterId: "",
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  bottomDialog: null,
  setBottomDialog: () => {},
  showMigrateButton: false,
  showNewUi: true,
  switchWorkspace: () => {},
  setAutoOpen: () => {},
  replacementsCount: 0,
  replacementsCompleteCount: 0,
  setReplacementsCount: () => {},
  setReplacementsCompleteCount: () => {},
};

export function useAppState() {
  const [state, dispatch] = useReducer(stateReducer, {
    ...defaultAppState,
    switchWorkspace,
    setDocumentSelectionData: (value: SelectionDetails) => {
      dispatch({ type: "SET_DOCUMENT_SELECTION_DATA", payload: value });
    },
    setSelectedDynamicElementIds: (value: number[]) => {
      dispatch({ type: "SET_SELECTED_DYNAMIC_ELEMENT_IDS", payload: value });
    },
    setIsFieldSetSelected: (value: boolean) => {
      dispatch({ type: "SET_IS_FIELD_SET_SELECTED", payload: value });
    },
    setBottomDialog: (value: JSX.Element | null) => {
      dispatch({ type: "SET_BOTTOM_DIALOG", payload: value });
    },
    toasterId: useId("toaster"),
    setIsLoggedIn: (state: boolean) => {
      dispatch({ type: "SET_IS_LOGGED_IN", payload: state });
    },
    setAutoOpen: () => {
      dispatch({ type: "SET_AUTO_OPEN", payload: true });
    },
    setReplacementsCount: (count: number) => {
      dispatch({ type: "SET_REPLACEMENTS_COUNT", payload: count });
    },
    setReplacementsCompleteCount: (count: number) => {
      dispatch({ type: "SET_REPLACEMENTS_COMPLETE_COUNT", payload: count });
    },
  } as AppState);

  function setCurrentUser(user: WalterUser) {
    defaultStorage.add("currentUser", user);
    dispatch({ type: "SET_CURRENT_USER", payload: user });
  }

  function switchWorkspace(workspace: WalterWorkspace | null) {
    defaultStorage.add("currentWorkspace", workspace);
    dispatch({ type: "UPDATE_CURRENT_WORKSPACE", payload: workspace });
  }

  return { state, dispatch, setCurrentUser, switchWorkspace };
}
