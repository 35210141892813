import {
  InsertConditionalContentControl,
  type InsertConditionalContentControlParams,
} from "@src/lib/sync-engine/operations/InsertConditionalContentControl";
import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";
import { ContentControlInsertionHelper } from "./ContentControlInsertionHelper";

export class InsertConditionalExpressionContentControl
  extends InsertConditionalContentControl
  implements Operation<void>
{
  constructor(params: InsertConditionalContentControlParams) {
    super(params);
  }

  public static async getRangeInfo(range: Word.Range, context: OperationContext) {
    const isEmpty = range.isEmpty;

    let isBlockSelection = true;

    if (!isEmpty) {
      let selectionOoXML = range.getHtml();
      await context.sync();
      const parser = new DOMParser();
      // This error is a false positive—we don't need to call load, we called sync .
      // eslint-disable-next-line office-addins/load-object-before-read, office-addins/call-sync-before-read
      const html = parser.parseFromString(selectionOoXML.value, "text/html");
      isBlockSelection = html.querySelectorAll("p").length > 1;
    }

    return { isEmpty, isBlockSelection };
  }

  public async commit(opContext: OperationContext) {
    const { control: wrapper } = await ContentControlInsertionHelper.insertContentControl(
      opContext.document.context,
      this.insertLocation,
    );

    const wrapperProperties: Word.Interfaces.ContentControlUpdateData = {
      appearance: Word.ContentControlAppearance.tags,
      cannotDelete: false,
      cannotEdit: false,
      title: this.title,
      color: this.color,
      tag: this.tag,
    };

    if (opContext.context.platform !== "OfficeOnline") {
      wrapperProperties.placeholderText = "Content goes here";
    }

    wrapper.set(wrapperProperties);

    if (this.hasElse) {
      const wrapperSelection = wrapper.getRange().load("isEmpty");
      await opContext.sync();
      const { isBlockSelection } = await InsertConditionalExpressionContentControl.getRangeInfo(
        wrapperSelection,
        opContext,
      );

      InsertConditionalExpressionContentControl.insertElseBranch({
        wrapper,
        insertAsBlock: isBlockSelection,
        context: opContext,
        color: this.color,
      });
    }

    wrapper.select();

    await opContext.sync();
  }

  public static insertElseBranch({
    wrapper,
    insertAsBlock,
    context,
    color,
  }: {
    wrapper: Word.ContentControl;
    insertAsBlock: boolean;
    context: OperationContext;
    color: string;
  }) {
    const elsePara = insertAsBlock
      ? wrapper.insertParagraph("Else content", "End")
      : wrapper.insertText("inline", "End");
    const elseControl = elsePara.insertContentControl();

    const elseControlProperties: Word.Interfaces.ContentControlUpdateData = {
      appearance: Word.ContentControlAppearance.tags,
      cannotDelete: false,
      cannotEdit: false,
      title: "Else",
      color: color,
      tag: "walter:else-branch",
    };

    if (context.context.platform !== "OfficeOnline") {
      elseControlProperties.placeholderText = "Else content";
    }

    elseControl.set(elseControlProperties);

    elseControl.getRange("Content").insertText("", "Replace");
  }
}
