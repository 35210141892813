import { FC } from "react";
import { Field, Link, makeStyles, ProgressBar, tokens } from "@fluentui/react-components";
import { CollapsibleSection } from "./CollapsibleSection";
import { SearchAndSelect } from "@src/lib/sync-engine/SearchAndSelect";

const useStyles = makeStyles({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: tokens.colorBackgroundOverlay,
    zIndex: 1000,
  },
  dialog: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorNeutralForeground1,
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    zIndex: 1001, // Ensure the dialog is above the overlay
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxHeight: "90vh", // Limit height to avoid overflow
    overflowY: "auto", // Enable scrolling if content exceeds the height
  },
  content: {
    padding: "12px 12px",
  },
});

export const ReplacePane: FC<{ total: number; current: number }> = ({ total, current }) => {
  const styles = useStyles();
  return (
    <div>
      <div className={styles.overlay}>
        <div className={styles.dialog}>
          <CollapsibleSection title={"Replacing"} collapsible={false}>
            <div className={styles.content}>
              <Field validationMessage={`Replacing ${current} of ${total}...`} validationState="none">
                <ProgressBar max={total} value={current} />
              </Field>
              <Link onClick={() => SearchAndSelect.forceQuit()}>Cancel</Link>
            </div>
          </CollapsibleSection>
        </div>
      </div>
    </div>
  );
};
