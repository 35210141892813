import { OperationContext } from "../OperationContext";
import { ContentControlUpdate } from "./ContentControlUpdate";
import { ContentControlInsertionHelper } from "./ContentControlInsertionHelper";

export class VariableContentControlInsert extends ContentControlUpdate {
  replacedText: string = "";
  insertedContentControlId: number | undefined = undefined;

  public async commit(context: OperationContext) {
    context.trace("VariableContentControl#add");

    const { control: newContentControl, replacedText } = await ContentControlInsertionHelper.insertContentControl(
      context.document.context,
      this.location,
    );

    await this.updateContentControl(context, newContentControl, Word.RangeLocation.after);

    this.replacedText = replacedText;
    this.insertedContentControlId = newContentControl.id;
  }
}
