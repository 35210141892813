import { FC, useContext, useEffect, useState, type JSX } from "react";
import { Delete24Regular } from "@fluentui/react-icons";
import { Button, Link } from "@fluentui/react-components";
import { InsertDynamicElement, QuickAdd } from ".";
import { ElementsPane, ErrorsPane } from "./ElementsPane";
import { CollapsibleSection } from "../CollapsibleSection";
import { openPreviewDialog } from "@src/lib/walter/Preview.tsx";
import { useHistory } from "react-router-dom";
import { OperationContext } from "@src/lib/sync-engine/OperationContext";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { Operation } from "@src/lib/sync-engine/operations/Operation";
import { useFields } from "@src/taskpane/hooks/useFields";
import { ChooseFieldSet } from "../FieldSets/ChooseFieldSet";
import { WalterContext } from "@src/taskpane/providers/walter";
import { DynamicElement } from "@src/lib/liquidx/internal";
import { AddFieldButton } from "@src/taskpane/components/AddFieldButton";
import { Intro } from "@src/taskpane/components/onboarding/Intro";
import useToasts from "@src/taskpane/hooks/useToasts";
import debug from "@src/lib/debug";
import { Toolbar } from "./Toolbar";
import { FocusedElementPane } from "./FocusedElementPane";
import { FieldsPane } from "../FieldsPane/FieldsPane";
import { MigrateStickyFooter } from "../MigrateStickyFooter";
import { useBottomDialog } from "@src/taskpane/hooks/useBottomDialog";
import { ReplacePane } from "../ReplacePane";

class DeleteAllDynamicElements implements Operation<void> {
  merge(other: Operation<unknown>) {
    if (other instanceof DeleteAllDynamicElements) {
      return this;
    }
  }

  async commit(context: OperationContext): Promise<void> {
    context.trace("Home#clearContentControls");

    const controls = context.document.contentControls;
    controls.load("items");
    await context.sync();

    for (const control of controls.items) {
      control.cannotEdit = false;
      control.cannotDelete = false;
      control.delete(true);
    }

    await context.sync();
  }
}

export const Home: FC<{ setMigrating: (_: boolean) => void }> = ({ setMigrating }) => {
  const history = useHistory();
  const { fields } = useFields();
  const {
    isFieldSetSelected,
    setIsFieldSetSelected,
    showNewUi,
    currentUser,
    replacementsCount,
    replacementsCompleteCount,
  } = useContext(WalterContext);
  const { showErrors } = useToasts();
  const [dialog, setDialog] = useState<Office.Dialog | null>(null);
  const [showPreviewAction, setShowPreviewAction] = useState(false);
  const { dialog: bottomDialog } = useBottomDialog();

  useEffect(() => {
    // If the user does not have a legal entity, they cannot preview a document
    if (currentUser?.recentLegalEntityId) {
      setShowPreviewAction(true);
    }

    if (fields.length > 0) {
      setIsFieldSetSelected(true);
    }
  }, [currentUser, fields]);

  async function previewDocument() {
    try {
      if (dialog) {
        // in the case where the dialog that was previously opened is now closed because it was closed manually
        // we need to catch the error that the .close() call will result in and then set the dialog to null
        try {
          dialog.close();
        } catch (e) {
          debug.info("Error closing dialog", e);
        }
        setDialog(null);
      }

      const result = await openPreviewDialog({ onError: displayErrorToast });

      if (result) {
        setDialog(result.value);
      }
    } catch (e) {
      displayErrorToast(e);
    }
  }

  function displayErrorToast(e: unknown, footerContent?: JSX.Element) {
    let message = "";

    if (!footerContent) {
      footerContent = <Link onClick={previewDocument}>Try again</Link>;
    }

    if (e instanceof Error) {
      message = e.message;
    } else if (typeof e === "string") {
      message = e;
    }

    if (message === "Another preview dialog is open. Please close it and try again.") {
      footerContent = undefined;
    }

    showErrors({
      title: "Error generating preview",
      messages: [message],
      footerContent,
    });
  }

  const clearContentControls = async () => {
    await syncEngine.perform(new DeleteAllDynamicElements());
    await DynamicElement.load();
  };

  const addField = () => {
    history.push("/fields/new");
  };

  if (!isFieldSetSelected && fields.length === 0) {
    return (
      <>
        <ChooseFieldSet />
        <Intro />
      </>
    );
  }

  return (
    <>
      {showNewUi && (
        <>
          <Toolbar preview={showPreviewAction ? previewDocument : undefined} />
          <FocusedElementPane />
        </>
      )}
      {!showNewUi && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "12px", paddingTop: "12px", paddingBottom: "12px" }}
        >
          {showPreviewAction && (
            <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
              <Button onClick={previewDocument}>Preview document</Button>
            </div>
          )}

          <InsertDynamicElement />
        </div>
      )}
      <div>
        <ElementsPane />
        <ErrorsPane />
        {showNewUi && <FieldsPane />}
        {!showNewUi && (
          <CollapsibleSection title="Fields">
            <AddFieldButton
              onClick={addField}
              onSaveFieldSetClick={() => history.push("/field-sets/new")}
            ></AddFieldButton>
            <QuickAdd />
          </CollapsibleSection>
        )}
        {showNewUi && bottomDialog}
      </div>
      {replacementsCount > 0 && <ReplacePane total={replacementsCount} current={replacementsCompleteCount} />}

      {!showNewUi && import.meta.env.DEV && (
        <div style={{ padding: "12px" }}>
          <Button icon={<Delete24Regular />} onClick={clearContentControls}>
            Clear Content Controls
          </Button>
        </div>
      )}
      <MigrateStickyFooter setMigrating={setMigrating} />
    </>
  );
};
